.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }
  .dd-list .dd-list {
    padding-left: 30px; }

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
 }

.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
  box-sizing: border-box; }
  .dd-handle:hover {
    color: #2ea8e5;
    background: #fff; }

.dd-item > button {
  position: absolute;
  left: -20px;
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  line-height: 1;
  text-align: center;
}
  .dd-item > button:before {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0; }
  .dd-item > button.dd-expand:before {
    content: '+'; }
  .dd-item > button.dd-collapse:before {
    content: '-'; }

.dd-expand {
  display: none; }

.dd-collapsed .dd-list,
.dd-collapsed .dd-collapse {
  display: none; }

.dd-collapsed .dd-expand {
  display: block; }

.dd-empty,
.dd-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: $yellowLight;
  // border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-empty {
  border: 1px dashed $yellow;
  min-height: 100px;
  // background-color: $yellowLight;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px; }

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999; }
  .dd-dragel > .dd-item .dd-handle {
    margin-top: 0; }
  .dd-dragel .dd-handle {
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); }

.dd-nochildren .dd-placeholder {
  display: none; }

////
// Custom
////

.dd-handle{
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 25px;
  padding: 0 20px;
  &:hover{cursor:grab;}
}

.dd-content{
  padding-left: 40px;
  &.root{
    padding-left: 10px;
    font-weight: bold;
    margin-bottom: $spacing-small;
  }
}

.dd-item > button{
  margin: 0;
}

.dd-item > button.dd-collapse::before {
  font-family: FontAwesome;
  content: $fa-var-minus;
}

.dd-item > button.dd-expand::before {
  font-family: FontAwesome;
  content: $fa-var-plus;
}
