@import "libs/01-settings.scss";
@import "libs/02-simplemde.scss";
@import "libs/03-select2.min.scss";
@import "libs/04-datepicker.scss";
@import "libs/05-colorpicker.scss";
@import "libs/06-selectize.scss";

@import "libs/99-fontawesome/variables";
@import "libs/99-fontawesome/mixins";
@import "libs/99-fontawesome/path";
@import "libs/99-fontawesome/core";
@import "libs/99-fontawesome/larger";
@import "libs/99-fontawesome/fixed-width";
@import "libs/99-fontawesome/list";
@import "libs/99-fontawesome/bordered-pulled";
@import "libs/99-fontawesome/animated";
@import "libs/99-fontawesome/rotated-flipped";
@import "libs/99-fontawesome/stacked";
@import "libs/99-fontawesome/icons";
@import "libs/99-fontawesome/screen-reader";

@import "includes/01-markdown-editor";
@import "includes/02-auth";
@import "includes/03-navigation";
@import "includes/04-tables";
@import "includes/05-alerts";
@import "includes/06-panels";
@import "includes/07-forms";
@import "includes/08-modal";
@import "includes/09-nestable";
@import "includes/10-datatables";


body{
  font-size: $font-size-regular;
  @extend .font-body;
  color: #222;
  background: #efefef;
  padding-top: 90px;
}

.container-fluid {
  padding-right: 40px;
  padding-left: 40px;
}

a,
a:active,
a:hover,
a.active{
  color: #0065ff;
}

h2{
  font-size: $font-size-sm;
  margin-bottom: $spacing-regular;
  font-weight: bold;
}


/* ----- Bootstrap hack ----- */

*{
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}


/* ----- Buttons ----- */

.btn{
  background-color: $white;
  padding: .5em 1.2em;
  letter-spacing: 1px;
  transition: 0.1s background-color linear, 0.1s color linear;
  border-width: 2px;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: 0;
}


.btn-default{
  border-color:$greyDark;
  color: $black;
  &:hover,
  &:active{
    border-color: $black;
    color: $black;
    background-color: $white;
  }

}

.btn-primary{
  border-color:$blue;
  color: $blue;
  &:hover,
  &:active,
  &:active:hover,
  &:focus,
  &:active:focus{
    color: $blueLight;
    background-color: $white;
    border-color:$blue;
    box-shadow: none;
  }
}

.btn-invert{
  border-color:$blue;
  color: white;
  background-color: $blue;
  &:hover,
  &:active{
    color: $blue;
    background-color: $white;
  }
}

.form-group.submit .btn{
  margin-right: 10px;
}


.delete-form{
  margin-bottom: $spacing-large;
}


/* ----- select2min ----- */

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  border: 2px solid $greyDark;
  min-height: 32px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: $blue;
  border: none;
  color: $white;
  // margin: $spacing-small $spacing-xsmall $spacing-xsmall $spacing-xsmall;
  padding: .2em .5em;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder,
.select2-container--default .select2-search--inline .select2-search__field,
.select2-search__field{
  letter-spacing: 1px;
  color: $greyDark;
}


.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  @extend .fa;
  @extend .fa-times;
  color: transparent;
  width: 0;
  margin-right: 18px;
  &:hover{
    color: transparent;
  }
  &:before{
    color: white;
  }
}


.article-edit{
  .slug{
    display: block;
    margin-top: 3px;
    color: $greyDark;
  }
}


/* ----- Wavves ----- */

.wavve{
  word-spacing: 10px;
  vertical-align: middle;
  letter-spacing: 4px;
  span{
    position: relative;
    display: inline-block;
  }
  span:nth-child(odd){
    top: 2px;
  }
}


/* ----- Tabs ----- */

.nav-tabs{
  border-width: 2px;
  margin-bottom: $spacing-regular;
  margin-left: -15px;
  margin-right: -15px;
  >li{
    margin-bottom: -2px;;
    &:first-of-type{
      margin-left: 15px;
    }
  }
  >li>a{
    border-radius: 10px 10px 0 0 !important;
    padding: 7px 20px;
    &:hover{
      background-color: $greyDark;
    }
  }
  >li.active>a,
  >li.active>a:focus,
  >li.active>a:hover {
    border: 2px solid $greyDark;
    border-bottom-color: transparent;
  }
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  background-color: $blue;
}


/* ----- Sortable ----- */

.sortable tr:hover,
.sortable li:hover,
.sortable li:hover a{
  cursor: move;
}

.sortable-ghost{
  opacity: 0;
}


/* ----- Loading ----- */

@keyframes blink {
  50% {opacity: 0.0}
}

.fa.blink {
  display: none;
  animation: blink .5s step-start 0s infinite;
  .loading &{display: inline}
}
