/* ----- Panels ----- */

.panel{
  border: none;
  box-shadow: none;
  > .panel-heading{
    background-color: $white;
    border-bottom: 3px solid $grey;
  }
  .tips{
    font-size: 10px;
    color: $greyDarker;
    font-weight: 400;
    padding-top: $spacing-small;
  }
  input[type="submit"]{
    margin-top: $spacing-regular;
  }
  .bt-add{
    color: $blue;
    margin-left: $spacing-regular;
  }
  .badge{
    background-color: $greyDark;
    font-weight: normal;
  }
}


.panel-heading{
  h3{
    display: inline;
    font-size: 14px;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
  a{
    margin-left: $spacing-regular;
  }
  .message{
    font-size: 10px;
    background-color: $yellow;
    position: relative;
  }
}

.panel-body.table-responsive{
  padding: 0;
}

.ghost{
  display: none;
}

.article-aside .list-group{
  margin-bottom:0;
}

.article-aside .list-group-item{
  font-size: 11px;
  letter-spacing: normal;
  border: none;
  padding: 0 0 15px 25px;
  .column-title{
    display: inline-block;
    width: 100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    color: $black;
    &:hover{
      cursor: pointer;
      text-decoration: none;
      i.fa-image{
        @extend .fa-ellipsis-h;
      }
    }
    &:focus,
    &:active{
      outline:0;
      text-decoration: none;
    }
  }
   &.has-action .column-title{
    width: calc(100% - 20px);
  }
  &:last-of-type &:not('.ghost'){
    margin-bottom: $spacing-small;
  }
  i {
    position: absolute;
    left: 0;
    font-size: 14px;
    line-height: 1;
    padding-right: $spacing-small;
  }
  a.action{
    line-height: 1;
    color: $blue;
    display: inline-block;
    width: auto;
    font-size: $font-size-regular;
  }
}

.media-panel{
  .panel-body ul, ol{
    padding-left: 0;
    padding-right: 0;
  }
  .m-delete{
    float: right;
    display: inline-block;
    color: $black;
  }
  input[type="file"]{
    display: none;
  }
  .media-add{
    color: $blue;
  }
}


.panel-related-articles{
  .group-select{
    margin-bottom: $spacing-regular;
  }
}
