
/* ----- Tables ----- */

.table{
  >thead:first-child>tr:first-child>td{
    vertical-align: middle;
  }

  tbody>tr>td,
  tbody>tr>th,
  tfoot>tr>td,
  tfoot>tr>th,
  thead>tr>td,
  thead>tr>th {
    padding: 15px;
    border-top: none;
  }
  .attribute{
    color: $greyDark;
  }
}

tbody,
.panel-body{
  tr{
    border-top: 1px solid $grey;
  }
  .published .fa-circle{
    position: relative;
    top: 3px;
    font-size: 10px;
  }
  .published .fa,
  .published-0 > .dd-handle > .fa{
    color: $red;
  }
  .published-1 .fa,
  .published-1 > .dd-handle > .fa{
    color: $green;
  }
  .fa-plus-circle{
    color: $blue !important;
  }
  a{
    color: $black;
  }
  a.add-article{
    color: $blue;
  }
  tr.level-2{
    border-top: 0;
    td{
      padding-top: 0;
      padding-bottom: 0;
      padding-left: $spacing-large;
    }
  }
  ul, ol{
    padding-left: $spacing-regular;
    padding-right: $spacing-regular;
    li{
      padding-top: $spacing-small;
      padding-bottom: $spacing-small;
      list-style-type: none;
    }
  }

  ol > li{
    border-bottom: 2px solid $grey;
  }
  ol ol > li{
    padding-top: $spacing-xsmall;
    padding-bottom: $spacing-xsmall;
    border-bottom: none;
  }
}

tfoot{
  border-top: 1px solid #DDD;
  >tr>td{
    border-top: none
  }
}
