

.dataTables_wrapper{
  .table {
    border: none;
    thead{
      th {
        padding-bottom:$spacing-xsmall mall  !important;
      }
    }
    tr {
      &:not(:last-of-type) {
        border-bottom: $border-width-sm solid $grey;
      }
      position:relative;
      th,
      td {
        padding: 0 5px 0 $spacing-xsmall ;
        max-width: 20vw;
        &.main-column{
          width:60%;
          max-width: 50vw;
          padding-top: $spacing-xsmall  * 1.5;
          padding-bottom: $spacing-xsmall  * 1.5;
          a {
            // @extend .link;
            border-bottom: none;
            color: $black;
          }
        }
        &:not(th):not(.main-column):not(.sorting_1){
          // @extend .ts-small;
          color:$black;
        }
        .text-content{
          display: block;
          width:100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.is-published {
          width: 40px;
          text-align: center;
          & + td,
          & + th {
            padding-left: 0;
          }
        }
        &:first-child {
          // padding-left :0;
          i{
            margin-bottom: 7px;
          }
        }
        &:last-child {
          text-align:right;
          padding-right :$spacing-xsmall ;
        }
      }
    }
  }

  .panel-body .highlight {
    background-color: $yellowLight;
  }

  .panel-footer {
    list-style: none;
    display:flex;
    justify-content: space-between;
    .dataTables_paginate {
      a {
        margin-right:$spacing-xsmall mall ;
        // @extend .link;
        border: none;
        // &.current {
        //   border-bottom: $border-width-sm solid;
        // }
      }
    }
    // @include media("<sm") {
    //   flex-direction: column-reverse;
    //   .dataTables_paginate {
    //     font-size: $font-size-lg;
    //     text-align: center;
    //     margin-bottom: $spacing-sm;
    //   }
    // }
  }
  .dataTables_info{
    // @extend %font-size-sm;
  }
  .dataTables_length,
  .dataTables_info {
    display: inline-block;
  }
  .dataTables_length {
    margin-right: $spacing-xsmall ;
    &:after {
      content: "/";
      margin-left: $spacing-xsmall mall ;
    }
  }
}

table.dt-rowReorder-float {
    position: absolute !important;
    opacity: 0.5;
    table-layout: fixed;
    z-index: 2001;
    max-height:40px;
    td {
      cursor:grabbing !important;
    }
    .text-content{
      width:100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

tr.dt-rowReorder-moving {
    outline: 1px solid $blue;
    outline-offset: -1px;
    max-height:32px;
}

body.dt-rowReorder-noOverflow {
    overflow-x: hidden;
}

// table.dataTable td.reorder {
//     text-align: center;
//     cursor: move;
// }


.table-reorderable > tbody {
  & > tr > td:last-child{
    position:relative;
    padding-right: 0 !important;
    .reorder {
      padding:$spacing-xsmall mall;
      cursor:grab;
      transition:0.2;
      &:hover{
        opacity:0.8;
      }
    }
  }
}

.datatable-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  font-size: 2.5rem;
  font-weight: bold;
  background-color: #efefef;
  ppadding:$spacing-regular;
}



.datatable{
  border: none;
  td{
    border: none;
  }

  .bt-add{
    margin: 15px
  }
  .is-published{
    width: 10px;
  }
  .fa{
    padding: 10px;
  }
}

.dataTables_filter{
  label{
    min-width: 50%;
  }
  input{
    padding: 15px;
    margin-top: 10px;
    width: 100%;
    @extend .form-control;
    height: 30px;
  }
}


.actions a{
  margin-left: 10px;
}
